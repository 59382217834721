import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider, Container } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Cart: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState(location.state?.cart || []);

  const handleQuantityChange = (index: number, action: string) => {
    const updatedCart = [...cart];
    if (action === 'increment') {
      updatedCart[index].quantity += 1;
    } else if (action === 'decrement' && updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
    }
    setCart(updatedCart);
  };

  const handleRemoveItem = (index: number) => {
    const updatedCart = cart.filter((_: any, i: number) => i !== index);
    setCart(updatedCart);
  };

  const handleClearCart = () => {
    setCart([]);
  };

  const handleProceedToCheckout = () => {
    navigate('/shop/cart/checkout', { state: { cart } });
  };

  const handleContinueShop = () => {
    navigate('/shop')
  }

  const cartTotal = cart.reduce((acc: number, item: any) => acc + item.price * item.quantity, 0);

  return (
    <Container>
      <Box sx={{ padding: 4 }}>
        <Box>
          <Typography variant="h5" gutterBottom>
            Items Information
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* Cart Items Section */}
          <Grid item xs={12} md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton color="error" onClick={() => handleRemoveItem(index)}>
                            <DeleteIcon />
                          </IconButton>
                          <img src={item.image} alt={item.name} style={{ width: 50, marginRight: 16 }} />
                          <Typography>{item.name}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>₹{item.price}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Button onClick={() => handleQuantityChange(index, 'decrement')}>-</Button>
                          <Typography>{item.quantity}</Typography>
                          <Button onClick={() => handleQuantityChange(index, 'increment')}>+</Button>
                        </Box>
                      </TableCell>
                      <TableCell>₹{item.price * item.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: 2 }}>
              <Button variant="contained" color="success" onClick={handleContinueShop}>
                Update cart
              </Button>
              <Button variant="outlined" color="error" onClick={handleClearCart}>
                Clear cart
              </Button>
            </Box>
          </Grid>

          {/* Cart Totals Section */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ padding: 3, backgroundColor: '#f8f9fa', borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Cart Totals
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Typography>Subtotal</Typography>
                <Typography>₹{cartTotal}</Typography>
              </Box>

              <Divider />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginY: 2 }}>
                <Typography>Shipping</Typography>
                <Typography>Free shipping</Typography>
              </Box>

              <Divider />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Typography>Total</Typography>
                <Typography>₹{cartTotal}</Typography>
              </Box>

              <Button
                variant="contained"
                color="success"
                fullWidth
                sx={{ marginTop: 3 }}
                onClick={handleProceedToCheckout}
              >
                Proceed to Checkout
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Cart;
