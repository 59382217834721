import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'
import Layout from './components/layout';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Blog from './pages/blog';
import Shopping from './pages/shopping';
import Checkout from './pages/checkout';
import Cart from './pages/cart';
import ProductDetail from './pages/productDetail';
import '@fontsource/mukta';

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/shop" element={<Shopping/>} />
          <Route path="/shop/product/:id" element={<ProductDetail />} />
          <Route path="/shop/cart" element={<Cart />} />
          <Route path="/shop/cart/checkout" element={<Checkout />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;