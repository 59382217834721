import React from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import heroImage from "../../images/heroImage.png";

const HeroSection: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        padding={isMobile ? "2rem" : "4rem"}
        alignItems="center"
        justifyContent="space-between"
        textAlign={isMobile ? "center" : "left"}
      >
        <Box flex={1} mb={isMobile ? 3 : 0}>
          <Typography
            variant={isMobile ? "h4" : "h2"}
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "white",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          >
            Accelerate with high-value solutions.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              color: "white",
              opacity: 0.9,
              lineHeight: 1.7,
              maxWidth: "90%",
              margin: isMobile ? "auto" : "0",
            }}
          >
            Delivering cutting-edge solutions for businesses in the digital
            age. From applications development to hardware retailing and server
            setups, we offer comprehensive services to meet your needs.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontStyle: "italic",
              color: "white",
              opacity: 0.8,
            }}
          >
            "It's not that we use technology, we live technology." - Godfrey
            Reggio
          </Typography>
          <Box marginTop="2rem">
            <Button
              variant="contained"
              size={isMobile ? "small" : "large"}
              sx={{
                padding: isMobile ? "0.5rem 1.5rem" : "0.75rem 2rem",
                fontSize: isMobile ? "0.875rem" : "1rem",
                fontWeight: "bold",
                borderRadius: "50px",
                backgroundColor: "#FF5A5F",
                color: "white",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "#FF7A7F",
                },
              }}
            >
              Get Started
            </Button>
          </Box>
        </Box>
        <Box
          flex={1}
          mt={isMobile ? 3 : 0}
          sx={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
          }}
        >
          <img
            src={heroImage}
            alt="tech"
            style={{
              width: isMobile ? "90%" : "auto",
              maxWidth: "450px",
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default HeroSection;
