export interface Peripheral {
    id: number;
    name: string;
    price: number;
    category: string;
    image: string;
  }
  
  export interface CartItem {
    item: Peripheral;
    quantity: number;
  }
  
  export interface CartState {
    items: Map<number, CartItem>;
  }
  
  export const ADD_TO_CART = 'ADD_TO_CART';
  export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
  