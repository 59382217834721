import React from 'react';
import { Typography, Grid, Box, Container, TextField, Button, IconButton } from '@mui/material';


const Contact: React.FC = () => {
  return (
  
      <Container maxWidth="lg" sx={{ height: '90vh' }}>
        <Box position='absolute' top='50%' left='45%' color='#c8c8c8'>
            <Typography variant='h3'>No Blogs yet</Typography>
        </Box>
    </Container>
     
  );
};

export default Contact;