import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CardMedia,
  Typography,
  Grid,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import { Peripheral } from '../shopping';

const ProductDetail: React.FC = () => {
  const { state } = useLocation();
  const { product } = state as { product: Peripheral }; // Destructure the product from state
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const addToCart = (item: Peripheral) => {
    // Add the product to the cart (you can integrate this with your cart logic)
    setSnackbarMessage(`${item.name} added to cart`);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const buyNow = () => {
    // Navigate to checkout page (or cart) with this item
    navigate('/shop/cart', { state: { cart: [{ ...product, quantity: 1 }] } });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {/* Product Image */}
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            height="400"
            image={product.image}
            alt={product.name}
            sx={{ objectFit: 'contain' }}
          />
        </Grid>

        {/* Product Details */}
        <Grid item xs={12} md={6}>
          <Box pl={2} pr={2}>
            <Typography variant="h4">{product.name}</Typography>
            <Typography variant="h6" color="text.secondary">
              ₹{product.price}
            </Typography>
          </Box>
          <Divider sx={{ marginY: 2 }} />

          {/* Product Specifications */}
          <Box pl={2} pr={2} pb={2}>
          <Typography variant="body1" gutterBottom>
            Product Specifications:
          </Typography>
          {/* You can add more detailed specifications here */}
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Category: {product.category}
            </Typography>
          </Box>

            {/* Add to Cart and Buy Now buttons */}
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addToCart(product)}
                  fullWidth
                  sx={{
                    borderRadius: '20px !important',
                    backgroundColor: 'rgb(27, 29, 54)',
                    marginBottom: { xs: '1rem', sm: 0 },
                  }}
                >
                  Add to Cart
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={buyNow}
                  fullWidth
                  sx={{
                    borderRadius: '20px !important',
                  }}
                >
                  Buy Now
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductDetail;
