import React from 'react';
import { Box, Grid, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../../components/heroSection';
import ServicesSection from '../../components/serviceSection'
import { services } from '../../types'
import Insight from '../../components/insight';

const Home: React.FC = () => {
  return (
    <Box>
      <Helmet>
        <title>Techkers Hubb</title>
        <meta name="description" content="We are dedicated to delivering cutting-edge solutions for businesses in the digital age. From web and mobile applications development to computer hardware retailing and server setups, we offer a comprehensive suite of services to meet your technology needs." />
      </Helmet>
      <Box style={{backgroundColor: "#1B1D36", color: "#fff"}}>
        <HeroSection />
      </Box>
     <Grid spacing={3}>
        <Grid item xs={12}>
          {/* <Typography variant="h3" gutterBottom>
            Our Services
          </Typography> */}
        </Grid>
        <Container>
        <Grid item xs={12}>
          <Box marginTop="3rem">
             <ServicesSection services={services} />
          </Box>
          <Box marginTop="3rem">
              <Insight />
          </Box>
        </Grid>
        </Container>
      </Grid>

    </Box>
  );
};

export default Home;