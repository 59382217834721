// src/pages/AboutUs.tsx
import React from 'react';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '2 rem',
  },
  header: {
    marginBottom: '2rem',
  },
  content: {
    marginBottom: '2rem',
  },
  card: {
    margin: '2rem',
  },
}));

const About: React.FC = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="md">
      <Typography variant="h3" className={classes.header} align="center" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" className={classes.content} paragraph>
        Welcome to Techkers Hubb, where innovation meets excellence. We are a leading technology company dedicated to providing cutting-edge solutions that empower businesses and individuals worldwide. Our mission is to drive progress and make a positive impact through our expertise in software development, hardware retailing, server setup & configutation, and data storage
      </Typography>
      <Typography variant="body1" className={classes.content} paragraph>
        Founded in 2017, Techkers Hubb has grown from a small startup to a global leader in technology services. Our team of highly skilled professionals is passionate about creating products and services that solve real-world problems and push the boundaries of what is possible. We pride ourselves on our culture of continuous learning and innovation, ensuring that we stay ahead of the curve in an ever-evolving industry.
      </Typography>
      <Typography variant="body1" className={classes.content} paragraph>
        At Techkers Hubb, we believe in the power of collaboration and partnership. We work closely with our clients to understand their unique needs and deliver tailored solutions that drive success. Our comprehensive range of services includes software development, cloud storage setups, data security solutions, IT consulting, and more.
      </Typography>
      <Typography variant="body1" className={classes.content} paragraph>
        Our commitment to excellence is reflected in everything we do. From our rigorous quality assurance processes to our dedication to customer satisfaction, we strive to exceed expectations and deliver exceptional value. We are proud to have a diverse and inclusive team that brings different perspectives and ideas to the table, fostering a creative and dynamic work environment.
      </Typography>
      <Typography variant="body1" className={classes.content} paragraph>
        As we look to the future, we remain focused on our core values of integrity, innovation, and impact. We are excited to continue our journey of growth and to help our clients navigate the digital landscape with confidence. Thank you for choosing Techkers Hubb as your trusted technology partner.
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Card >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Our Mission
              </Typography>
              <Typography variant="body1" paragraph>
                To drive progress and make a positive impact through our expertise in software development, data storage, cybersecurity, and cloud computing.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Our Vision
              </Typography>
              <Typography variant="body1" paragraph>
                To be a global leader in technology services, known for our innovative solutions and exceptional customer service.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Our Values
              </Typography>
              <Typography variant="body1" paragraph>
                Integrity, Innovation, and Impact.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Our Team
              </Typography>
              <Typography variant="body1" paragraph>
                A diverse and inclusive team of highly skilled professionals passionate about technology and innovation.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
