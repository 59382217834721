import React from "react";
import { Grid, Typography, Box, List, ListItem, ListItemText,  useMediaQuery, useTheme, ListItemIcon  } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
import insightIcon from '../../images/insight.gif'
import insightImg from '../../images/insightImg.jpg'

const Insight: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={4} alignItems="center">
        {/* Left Side: Text Content */}
        <Grid item xs={12} md={6}>
        <Box>
        <img src={insightIcon} width='100px' alt="insight"/>
        </Box>
        <Typography
            variant={isMobile ? "h4" : "h3"}
            gutterBottom
            sx={{
              fontWeight: "bold",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          >
            Advanced Data Insights, Grasp Business Strategies
          </Typography>
          <Typography variant="body1" paragraph>
            Uniquely advance interconnected portals alongside widespread digital
            marketplaces. Actively assess and enhance end-to-end solutions to
            refine forward-thinking leadership strategies without draining
            resources on unproductive ideas.
          </Typography>
          <List>
            {[
              "Leadership innovation",
              "Strengthening personal brand identity",
              "Upgrading prospecting techniques",
              "Increasing success rates",
              "Highlighting achievements",
              "Adhering to sales regulations",
            ].map((text, index) => (
              <ListItem key={index}>
                 <ListItemIcon>
                  <CheckCircleIcon sx={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Right Side: Image */}
        <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={insightImg}
            alt="Analytics insight"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Insight;
