import React, { Children, ReactNode } from 'react';
import Navbar from '../navbar';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import Footer from '../footer';
import HeroSection from '../heroSection';
import ServicesSection from '../serviceSection';
import { services } from '../../types'
import About from '../../pages/about';
import Contact from '../../pages/contact';

interface LayoutProps {
    children: ReactNode;
  }

const Layout: React.FC<LayoutProps> = ({children}) => {
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Navbar />
       {children}
      <Footer />
    </>
  );
};

export default Layout;