import { makeStyles } from '@mui/styles';
import { Typography, Link, Container, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// Define styles using makeStyles hook
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px',
    background: '#494949',
    marginTop: 'auto',
    color: '#ffffff'
  },
  // navStyles: {
  //   marginRight: '2rem',
  //   textDecorationLine: 'none',
  //   textDecorationColor: '#fff'
  // }
}));

const Footer: React.FC = () => {
  const theme = useTheme(); // Access theme using useTheme hook
  const classes = useStyles(theme); // Pass theme to useStyles

  return (
    <footer className={classes.root}>
      <Container maxWidth="lg">
        <Typography variant="body1">Techkers Hubb</Typography>
        <hr />
        <Typography variant="caption" align="center">
          &#169;{new Date().getFullYear()}. Techkers Hubb. All rights reserved
        </Typography>
        <Box>
       
          {/* <Link variant="body2" className={classes.navStyles} onClick={() => {
              
            }}
          >
            Privacy policy
          </Link>
          <Link variant="body2" className={classes.navStyles} onClick={() => {
              
            }}
          >
            Terms of use
          </Link> */}
       
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
