import React from 'react';
import { Typography, Grid, Box, Container, TextField, Button, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Helmet } from 'react-helmet-async';

const Contact: React.FC = () => {
  return (
    <Box>
      <Helmet>
        <title>Contact | Techkers Hubb</title>
        <meta name="description" content="This is the about page of MyApp." />
      </Helmet>
      <Box>
      <Container maxWidth="lg" sx={{ paddingTop: '40px' }}>
      <Grid container spacing={4}>
        {/* Left Section - Contact Information */}
        <Grid item xs={12} md={4}>
          <Typography variant="h4" gutterBottom>Contact Us</Typography>
          <Typography variant="body1" paragraph>
            Any question? We would be happy to help you!
          </Typography>

          <Box display="flex" alignItems="center" mb={2}>
            <PhoneIcon fontSize="large" />
            <Typography variant="body1" ml={2}>+0123456789</Typography>
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <EmailIcon fontSize="large" />
            <Typography variant="body1" ml={2}>info@techkershubb.com</Typography>
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <LocationOnIcon fontSize="large" />
            <Typography variant="body1" ml={2}>IT Rolling Green Rd.</Typography>
          </Box>

          {/* Social Icons */}
          <Box mt={3} display="flex" gap={2}>
            <IconButton aria-label="Facebook">
              <FacebookIcon fontSize="large" />
            </IconButton>
            <IconButton aria-label="Twitter">
              <TwitterIcon fontSize="large" />
            </IconButton>
            <IconButton aria-label="Instagram">
              <InstagramIcon fontSize="large" />
            </IconButton>
          </Box>
        </Grid>

        {/* Right Section - Contact Form */}
        <Grid item xs={12} md={8}>
          <Box component="form">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="First Name" 
                  variant="outlined" 
                  fullWidth 
                  required 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="Last Name" 
                  variant="outlined" 
                  fullWidth 
                  required 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  label="Email" 
                  type="email" 
                  variant="outlined" 
                  fullWidth 
                  required 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  label="Phone Number" 
                  type="tel" 
                  variant="outlined" 
                  fullWidth 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  label="Message" 
                  variant="outlined" 
                  fullWidth 
                  multiline 
                  rows={4} 
                  required 
                />
              </Grid>
            </Grid>
            <Button 
              variant="contained" 
              color="primary" 
              size="large" 
              sx={{ marginTop: '20px', marginBottom: '30px' }}
              endIcon={<EmailIcon />}
            >
              Send Message
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={4} mb={4}>
        <iframe
          title="Google Map Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.086128508639!2d-122.08503268468142!3d37.4220658798271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fba124eaaaaab%3A0xf56e1ff1a52bff9e!2sGoogleplex!5e0!3m2!1sen!2sus!4v1617623701925!5m2!1sen!2sus"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
        ></iframe>
      </Box>
    </Container>
      </Box>
    </Box>
  );
};

export default Contact;