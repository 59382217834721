import { CartState, ADD_TO_CART, REMOVE_FROM_CART } from './types';

const initialState: CartState = {
  items: new Map(),
};

const Reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_TO_CART:
      const item = action.payload;
      const updatedItems = new Map(state.items);
      if (updatedItems.has(item.id)) {
        updatedItems.set(item.id, {
          ...updatedItems.get(item.id)!,
          quantity: updatedItems.get(item.id)!.quantity + 1,
        });
      } else {
        updatedItems.set(item.id, { item, quantity: 1 });
      }
      return { ...state, items: updatedItems };

    case REMOVE_FROM_CART:
      const newState = { ...state };
      newState.items.delete(action.payload);
      return newState;

    default:
      return state;
  }
};

export default Reducers;
